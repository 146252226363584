










































import { Component, Vue, Watch } from "vue-property-decorator";
import { User } from "@/types/User";
import { Pager } from "@/types/Pager";
import { License } from "@/types/License";
import { loadUsers } from "@/api/users";
import { convertMoney } from "@/common/helper";

@Component({
  components: {}
})
export default class Users extends Vue {
  data: User[] = [];
  pagination: Partial<Pager> = {};
  isLoading = false;
  isLoadPagination = false;
  convertMoney = convertMoney;
  columns = [
    {
      title: "Email",
      key: "email",
      dataIndex: "email"
    },
    {
      title: "Licenses",
      key: "licenses",
      dataIndex: "licenses",
      scopedSlots: { customRender: "licenses" }
    },
    {
      title: "Credits",
      key: "credits",
      dataIndex: "credits",
      scopedSlots: { customRender: "credits" }
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "20%",
      scopedSlots: { customRender: "action" }
    }
  ];
  searchEmail = "";
  debouncedLoadUsers = this.lodash.debounce(this.loadUsers, 500);

  mounted() {
    this.loadUsers(1);
  }

  handleTableChange(pagination: Pager) {
    const pager: Partial<Pager> = { ...this.pagination };
    pager.current = pagination.current;
    this.pagination = pager;
    if (pager.current) {
      this.loadUsers(pager.current);
    }
  }

  async loadUsers(page: number) {
    this.isLoading = true;
    const { data } = await loadUsers(page, this.searchEmail);
    this.data = data.data;
    this.isLoading = false;
    this.pagination.current = data.currentPage;
    this.pagination.pageSize = data.perPage;
    this.pagination.total = data.total;
    this.isLoadPagination = true;
  }

  activeLicenses = (licenses: License[]) => licenses.filter(item => item.status);

  @Watch("searchEmail")
  emailSearch() {
    this.debouncedLoadUsers(1);
  }
}
